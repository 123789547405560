import React, { useState } from 'react';

const CookiePolicy = () => {
  const [email, setEmail] = useState('Cliquez pour afficher');

  const revealEmail = (e) => {
    e.preventDefault();
    const revealedEmail = 'f.acc@hotmail.fr';
    setEmail(revealedEmail);
    window.location.href = `mailto:${revealedEmail}`;
  };

  return (
    <div>
      <h1>Conditions Générales d'Utilisation des Cookies</h1>

      <h3>Introduction</h3>
      <p>
        Ce document décrit les conditions générales d'utilisation des cookies sur le site internet du festival d'art contemporain des Comores. En utilisant notre site, vous consentez à l'utilisation de cookies conformément à cette politique.
      </p>

      <h3>Qu'est-ce qu'un cookie ?</h3>
      <p>
        Un cookie est un petit fichier texte qui est enregistré sur votre appareil lorsque vous visitez notre site. Les cookies permettent au site de reconnaître votre appareil et de mémoriser certaines informations sur votre visite.
      </p>

      <h3>Types de cookies utilisés</h3>
      <ul>
        <li><strong>Cookies de session</strong> : Ces cookies sont temporaires et expirent lorsque vous fermez votre navigateur.</li>
        <li><strong>Cookies persistants</strong> : Ces cookies restent sur votre appareil pendant une période déterminée ou jusqu'à ce que vous les supprimiez.</li>
     </ul>

      <h3>Données collectées</h3>
      <p>
        Les données collectées par le biais des cookies incluent, mais ne se limitent pas à :
      </p>
      <ul>
        <li>L'adresse IP</li>
        <li>Les pages visitées</li>
        <li>La durée de la visite</li>
        <li>Les interactions avec le contenu</li>
      </ul>

      <h3>Utilisation des données</h3>
      <p>
        Les données collectées par les cookies sont utilisées pour :
      </p>
      <ul>
        <li>Analyser le comportement des visiteurs pour améliorer notre site et nos services.</li>
        <li>Personnaliser l'expérience utilisateur.</li>
        <li>Communiquer avec les visiteurs via des campagnes par email, en utilisant des informations sur les visiteurs du site.</li>
      </ul>

      <h3>Consentement</h3>
      <p>
        En visitant notre site, vous consentez à l'utilisation de cookies conformément à cette politique. Vous avez la possibilité de gérer vos préférences en matière de cookies via les paramètres de votre navigateur.
      </p>

      <h3>Gestion des cookies</h3>
      <p>
        Vous pouvez contrôler et gérer les cookies de plusieurs manières :
      </p>
      <ul>
        <li><strong>Paramètres du navigateur</strong> : La plupart des navigateurs vous permettent de refuser les cookies ou de vous alerter lorsque des cookies sont envoyés.</li>
        <li><strong>Outils de gestion des cookies</strong> : Vous pouvez utiliser des outils tiers pour gérer vos préférences en matière de cookies.</li>
      </ul>

      <h3>Modifications de cette politique</h3>
      <p>
        Nous nous réservons le droit de modifier cette politique de cookies à tout moment. Les modifications seront publiées sur cette page.
      </p>

      <h3>Contact</h3>
      <p>
        Pour toute question concernant cette politique de cookies, veuillez nous contacter à l'adresse email suivante : {' '}
        <a href="#" onClick={revealEmail}>{email}</a>.
      </p>
    </div>
  );
};

export default CookiePolicy;